// mutation types
export const UPDATE_PAYMENT_SORT_BY = "updatePaymentSortBy";
export const SET_PAYMENT_SORT_BY = "setPaymentSortBy";

const state = {
  payments:{
    sort_by: '',
  }
};

const getters = {
  currentPaymentSortBy(state) {
    return state.payments.sort_by;
  },
};

const actions = {
  [UPDATE_PAYMENT_SORT_BY](context, payload) {
    context.commit(SET_PAYMENT_SORT_BY, payload);
  },
};

const mutations = {
  [SET_PAYMENT_SORT_BY](state, payload) {
    state.payments.sort_by = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};